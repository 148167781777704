<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <div class="container">
      <div class="row">
        <div class="col" v-if="farmas_bool">
          <div class="form-group">
            <label for="provider">Fornecedor</label>
            <input
              class="form-control"
              type="text"
              :value="this.getInvoicesSuppliersId[0].supplier"
            />
          </div>
          <div class="form-group">
            <label for="invoiceNumber">Número da Fatura</label>
            <input
              type="text"
              class="form-control"
              id="invoiceNumber"
              v-model="invoiceId"
            />
          </div>
          <!-- DATA -->
          <!--  <div class="form-group">
              <label for="date">Data</label>
              <input
                type="date"
                class="form-control"
                v-model="date_if"
                disabled
              />
            </div> -->
        </div>
        <div class="col" id="col1" v-else>
          <form>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Fornecedor</span
                >
              </div>
              <v-select
                @input="updateInvoiceType()"
                :options="suppliers"
                v-model="supplier"
                :reduce="(supp) => supp.name"
                label="name"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }}</template
                >
                <template slot="selected-option" slot-scope="option">
                  {{ option.name }}</template
                >
              </v-select>
              <b-button v-if="supp_url != null" @click="openSite()"
                >🌐</b-button
              >
              <!-- <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="supplier"
                >
                  <option v-for="supplier in suppliers" :key="supplier.id">
                    {{ supplier.name }} ({{ supplier.other_name }})
                  </option>
                </select> -->
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >NIF</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="supp_nif"
                disabled
              />
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Contacto</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="supp_contact"
                disabled
              />
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Nº Fatura</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="invoiceId"
              />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><a v-on:click="$bvModal.show('myModal')">📄</a></span
                >
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Tipo Fatura</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="tipo_fatura"
                disabled
              />
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Pagamento</span
                >
              </div>
              <select
                class="form-select form-select"
                id="exampleFormControlSelect1"
                v-model="paymentMethod"
                style="font-size: 100%"
              >
                <option value=""></option>
                <option value="PayPal">PayPal</option>
                <option value="Referência MB">Referência MB</option>
                <option value="Cartão Crédito">Cartão Crédito</option>
                <option value="Transferência Bancária">
                  Transferência Bancária
                </option>
              </select>
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Pago</span
                >
              </div>
              <input type="number" v-model="totalPay" class="form-control" />
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >IMG Receção</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="img_reception"
                @change="addImgReception()"
              />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><a v-on:click="img_reception ? openImage() : null"
                    >🖼️</a
                  ></span
                >
              </div>
            </div>
            <div class="input-group input-group-sm mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Nota de Crédito</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                id="invoiceNumber"
                v-model="creditNoteNumber"
              />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><a v-on:click="$bvModal.show('creditNoteModal')">📄</a></span
                >
              </div>
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm"
                  >Valor N.Crédito</span
                >
              </div>
              <input
                type="number"
                class="form-control"
                v-model="creditNoteValue"
              />
            </div>
          </form>
        </div>
        <div class="col">
          <div class="row">
            <div class="column">
              <div class="box box1">
                <img :src="img" />
              </div>
            </div>
            <div class="column">
              <form>
                <div class="form-group">
                  <label for="sku">SKU</label>
                  <input
                    type="text"
                    class="form-control"
                    id="sku"
                    v-model="skuInfo"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label for="ean">EAN</label>
                  <input
                    type="text"
                    class="form-control"
                    id="ean"
                    v-model="eanInfo"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label for="productName">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    id="productName"
                    disabled
                    v-model="nameInfo"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <form v-on:submit.prevent="getProduct()">
        <div id="searchEan" class="form-group row">
          <label for="productEan" id="lblProductEan" class="col-sm col-form-label"
            ><b>Cód. Produto:</b></label
          >
          <div class="col-sm">
            <input
              type="text"
              class="form-control"
              id="productEan"
              autofocus
              v-model="ean"
            />
          </div>
          <div class="col-sm">
            <button class="btn btn-primary">Adicionar</button>
          </div>
        </div>
      </form> -->
    <table class="table" id="newOrder">
      <thead>
        <tr>
          <th scope="col">SKU</th>
          <th scope="col" style="width: 35%">Nome</th>
          <!-- <th scope="col">Qtd.</th> -->
          <th scope="col">Qtd. Conf.</th>
          <th scope="col" v-if="!farmas_bool">Preço (c/IVA)</th>
          <th scope="col" v-else>Preço</th>
          <th scope="col" style="width: 7%">IVA %</th>
          <th scope="col">Desc.</th>
          <th scope="col">Desc. %</th>
          <!-- <th scope="col" style="width: 3%">Validade</th> -->
          <th scope="col">Peso (g)</th>
          <th scope="col">Total</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, index) in newProducts"
          :key="product.id"
          :style="
            product.sku == skuInfo
              ? { 'background-color': '#ff9393' }
              : { 'background-color': 'white' }
          "
        >
          <td>
            <a
              id="openArticle"
              v-b-modal.modal-3
              @click="passProductData(product)"
              >{{ product.sku }}</a
            >
          </td>
          <td>{{ product.name }}</td>
          <td
            v-if="
              newProducts[index].qty_confirmed == undefined ||
              newProducts[index].qty_confirmed == 0
            "
          >
            <input
              type="text"
              class="form-control"
              v-model="newProducts[index].qty_received"
              disabled
            />
          </td>
          <td v-else>
            <input
              type="text"
              class="form-control"
              v-model="newProducts[index].qty_confirmed"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].price"
              disabled
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].iva"
              disabled
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].discount"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              v-model="newProducts[index].discount_per"
              disabled
            />
          </td>
          <!-- <td>
            <date-picker
              v-model="newProducts[index].validity"
              type="month"
              format="YYYY-MM"
              value-type="format"
              :lang="lang"
            ></date-picker>
            <span id="infinity" @click="setDataInfinity(newProducts[index])">
              Não tem validade
            </span>
          </td> -->
          <td>
            <input
              class="form-control"
              type="number"
              v-model="newProducts[index].weight"
            />
          </td>
          <td
            v-if="
              newProducts[index].discountPer == '' ||
              newProducts[index].discountPer == undefined
            "
          >
            {{
              (newProducts[index].total =
                Number(newProducts[index].price) *
                  Number(newProducts[index].qty_confirmed) -
                newProducts[index].discount)
            }}€
          </td>
          <td v-else>
            {{
              (newProducts[index].total =
                Number(newProducts[index].price) *
                Number(newProducts[index].qty_confirmed) *
                (1 - newProducts[index].discountPer / 100)).toFixed(2)
            }}€
          </td>
          <td>
            <button
              type="button"
              class="btn btn-danger"
              @click="removeProduct(product.id)"
            >
              -
            </button>
          </td>
          <!-- <span style="display: none">
            {{
              (newProducts[index].valueWithOutIVA = (
                (Number(newProducts[index].value) - Number(newProducts[index].discount)) /
                1.23
              ).toFixed(2))
            }}
          </span> -->
        </tr>
      </tbody>
    </table>
    <div>
      <h5>
        Desconto
        <input type="number" v-model="discount" max-length="8" />
      </h5>
      <h5>
        Total:
        <b>
          {{
            this.newProducts
              .reduce((sum, product) => {
                return (
                  sum +
                  (Number(product.qty_confirmed) * Number(product.price) -
                    Number(product.discount))
                );
              }, Number(-this.discount))
              .toFixed(2)
          }}€
        </b>
      </h5>
      <h5 v-if="!farmas_bool">
        Total s/IVA:
        <b>
          {{
            (
              this.newProducts.reduce((sum, product) => {
                return (
                  sum + Number(product.qty_confirmed) * Number(product.price)
                );
              }, Number(-this.discount)) / 1.23
            ).toFixed(2)
          }}€
        </b>
      </h5>

      <div class="buttons">
        <!-- <button
            type="submit"
            class="btn btn-primary"
            @click="updateInvoice('processing')"
          >
            Guardar
          </button>
          <button
            v-if="!farmas_bool"
            type="submit"
            class="btn btn-warning"
            @click="saveWithoutInvoice('invoice')"
          >
            Guardar s/ Fatura
          </button> -->
        <button type="submit" class="btn btn-success" @click="updatePurchase()">
          Editar
        </button>
      </div>
      <b-modal
        ref="modal_product"
        id="modal-3"
        title="Produto"
        size="lg"
        hide-footer
      >
        <div class="card">
          <b-container>
            <b-row align-v="center"
              ><b-col class="left">
                <!-- IMAGEM DO PRODUTO -->
                <img :src="imgProduct" />
              </b-col>
              <b-col>
                <p><span id="subtitle">STOCK: </span> {{ stockProduct }}</p>
                <p><span id="subtitle">EAN:</span> {{ eanProduct }}</p>
                <p><span id="subtitle">SKU:</span> {{ skuProduct }}</p>
                <p><span id="subtitle">HS Code:</span> {{ hsProduct }}</p>
                <p>
                  <span id="subtitle">Notas Arm.:</span>
                  {{ warehouseProduct }}
                </p>
                <p><span id="subtitle">Notas:</span> {{ notesProduct }}</p>
                <p><span id="subtitle">Peso:</span> {{ weightProduct }} (g)</p>
              </b-col></b-row
            >
          </b-container>
          <button class="btn btn-info" @click="openProdPage()">
            Editar Produto
          </button>
        </div>
      </b-modal>

      <b-modal id="myModal" title="Fatura da Receção">
        <div style="text-align: center">
          <button
            class="btn btn-primary"
            :disabled="!file ? true : false"
            @click="openInvoice()"
          >
            Ver Fatura Inserida
          </button>
        </div>
        <div class="form-group">
          <label for="fileInput">Inserir Fatura da Receção</label>
          <input
            type="file"
            class="form-control"
            id="fileInput"
            @change="handleFileUpload"
          />
        </div>
        <input
          type="text"
          class="form-control"
          id="textInput"
          v-model="invoiceUrl"
          placeholder="Link da Fatura"
        />
        <div slot="modal-footer">
          <button class="btn btn-primary" @click="addFileReception()">
            Adicionar Fatura
          </button>
          <b-button @click="$bvModal.hide('myModal')">Fechar</b-button>
        </div>
      </b-modal>

      <b-modal id="creditNoteModal" title="Nota de Crédito">
        <div style="text-align: center">
          <button
            class="btn btn-primary"
            :disabled="!fileCreditNote ? true : false"
            @click="openCreditNote()"
          >
            Ver Nota de crédito
          </button>
        </div>
        <div class="form-group">
          <label for="fileInput">Inserir Nota de Crédito</label>
          <input
            type="file"
            class="form-control"
            id="fileInput"
            @change="handleFileCreditNoteUpload"
          />
        </div>
        <input
          type="text"
          class="form-control"
          id="textInput"
          v-model="creditNoteURL"
          placeholder="Link da Fatura"
        />
        <div slot="modal-footer">
          <button class="btn btn-primary" @click="addCreditNoteFile()">
            Adicionar Nota de Crédito
          </button>
          <b-button @click="$bvModal.hide('creditNoteModal')">Fechar</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      eans: [],
      ean: "",
      products: [],
      productInfo: [],
      skuInfo: "",
      eanInfo: "",
      nameInfo: "",
      img: "https://www.svgrepo.com/show/97910/image.svg",
      newProducts: [],
      invoiceId: "",
      tipo_fatura: "",
      supp_contact: "",
      supp_nif: "",
      supp_url: "",
      supplier: "",
      date: "",
      suppliers: [],
      val_date_min: "",
      val_date_max: "",
      date_if: "",
      farmas_bool: false,
      loading: false,
      discount: 0,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
          ],
          // MMM
          monthsShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ],
          // dddd
          weekdays: [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
          ],
          // ddd
          weekdaysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          // dd
          weekdaysMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
        },
      },
      paymentMethod: "",
      totalPay: 0,
      eanProduct: "",
      skuProduct: "",
      hsProduct: "",
      warehouseProduct: "",
      notesProduct: "",
      weightProduct: "",
      imgProduct: "",
      idProduct: "",
      stockProduct: "",
      img_reception: "",
      invoiceUrl: "",
      invoiceFile: "",
      file: "",
      fileUrl: "",
      creditNoteURL: "",
      creditNoteFile: "",
      creditNoteValue: "",
      creditNoteNumber: "",
      fileCreditNote: "",
      newProductsCopy: [],
    };
  },
  methods: {
    async openProdPage() {
      window.open(
        `https://${window.location.host}/product/${this.idProduct}`,
        "_blank"
      );
      this.$refs["modal_product"].hide();
    },
    async openSite() {
      window.open(this.supp_url, "_blank");
    },
    /* OBTER A LISTA DE TODOS OS PRODUTOS */
    async getAllProducts() {
      /* this.loading = true; */
      try {
        await this.$store.dispatch("fetchProducts");
        this.products = this.getProducts.data;
      } catch (err) {
        alert(err);
      } /* finally {
          this.loading = false;
        } */
    },
    /* RECEBER DADOS DA ENCOMENDA JÁ INSERIDA */
    async getDataInformation() {
      /* ID */
      let date = new Date();
      this.val_date_min = `${date.getFullYear()}-${date.getMonth() + 1}`;
      this.val_date_max = `${date.getFullYear() + 5}-${date.getMonth() + 1}`;

      let id = this.$route.params.id;
      await this.$store.dispatch("getInvoicesSuppliersByID", {
        id: id,
      });
      console.log("QUERO VER ESTE", this.getInvoicesSuppliersId);
      if (
        this.getInvoicesSuppliersId.supplier == "Farmacia Alvim" ||
        this.getInvoicesSuppliersId.supplier == "Farmacia Gomes" ||
        this.getInvoicesSuppliersId.supplier == "Farmacia Perelhal" ||
        this.getInvoicesSuppliersId.supplier == "Farmacia Gomes" ||
        this.getInvoicesSuppliersId.supplier == "Farmacia Alvim (OCP)"
      ) {
        this.farmas_bool = true;
      }
      let date_iff = this.getInvoicesSuppliersId.date_request;
      let newDate1 = new Date(date_iff.split("T"));
      let year1 = newDate1.getFullYear();
      let month1 = ("0" + (newDate1.getMonth() + 1)).slice(-2);
      let day1 = ("0" + newDate1.getDate()).slice(-2);
      this.date_if = `${year1}-${month1}-${day1}`;

      console.log(this.getInvoicesSuppliersId.transactions);
      this.supplier = this.getInvoicesSuppliersId.supplier;
      const help = this.suppliers.find((item) => item.name == this.supplier);
      console.log(help);
      this.supp_url = help.url || null;
      this.tipo_fatura = help.invoice;
      this.supp_contact = help.contact;
      this.supp_nif = help.nif;
      console.log(this.supplier);
      /* DATA DA FATURA */
      let date_invoice = this.getInvoicesSuppliersId.date_invoice;
      /* CASO JÁ TENHA SIDO INSERIDO DATA */
      if (date_invoice == null) {
        this.date = date_invoice;
      } else {
        /* TEMOS QUE CRIAR UMA NOVA DATA COM A INFORMAÇÂO RECEBIDA POIS A DATA VEM COM O DIA ANTERIOR
          FORMATO DA DATA YYYY-MM-DDTHH:MM:SSZ TEMOS QUE EXPORTAR SÓ O YYYY-MM-DD */
        let newDate = new Date(date_invoice.split("T"));
        let year = newDate.getFullYear();
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let day = ("0" + newDate.getDate()).slice(-2);
        this.date = `${year}-${month}-${day}`;
      }
      console.log(this.getInvoicesSuppliersId);
      this.invoiceId = this.getInvoicesSuppliersId.bill_id;
      this.paymentMethod = this.getInvoicesSuppliersId.payment_method;
      this.totalPay = this.getInvoicesSuppliersId.bill_value;
      this.newProducts = this.getInvoicesSuppliersId.transactions;
      this.newProductsCopy = this.newProducts.map((product) => ({
        ...product,
      }));
      this.discount = this.getInvoicesSuppliersId.discount;
      this.img_reception = this.getInvoicesSuppliersId.img;
      this.file = this.getInvoicesSuppliersId.file;
      this.creditNoteValue = this.getInvoicesSuppliersId.credit_note_value;
      this.creditNoteNumber = this.getInvoicesSuppliersId.credit_note_number;
      this.fileCreditNote = this.getInvoicesSuppliersId.credit_note_file;
    },
    async setDataInfinity(product) {
      console.log("Infinito");
      console.log(product.validity);
      product.validity = "2050-01";
      console.log(product);
    },
    async updateInvoiceType() {
      const help = this.suppliers.find((item) => item.name == this.supplier);
      this.supp_url = help.url;
      this.tipo_fatura = help.invoice;
      this.supp_contact = help.contact;
      this.supp_nif = help.nif;
    },
    async getEAN() {
      try {
        await this.$store.dispatch("fetchProductsEAN");
        this.eans = this.getProductsEAN;
        console.log(this.eans);
      } catch (err) {
        alert(err);
      }
    },
    async getProduct() {
      let ean = this.eans.filter((ean) => ean.ean == this.ean);
      /* CASO O VALOR INSERIDO NÃO SEJA DETECTADO VERIFICAMOS SE FOI UM SKU INTRODUZIDO */
      if (ean.length == 0) {
        ean = this.products.filter((product) => product.sku == this.ean);
      }
      if (ean.length != 0) {
        this.productInfo = this.products.filter(
          (product) => product.sku == ean[0].sku
        );
        this.nameInfo = this.productInfo[0].name;
        this.eanInfo = this.ean;
        this.skuInfo = this.productInfo[0].sku;
        this.img = this.productInfo[0].img;
        let validity = "";
        let weight = "";
        let index = this.newProducts.findIndex(
          (prod) => prod.sku == ean[0].sku
        );
        console.log("INDEX: ", index);
        if (index == -1) {
          if (this.productInfo[0].validity != null) {
            validity = this.productInfo[0].validity.substring(0, 7);
          }
          if (this.productInfo[0].weight != null) {
            weight = this.productInfo[0].weight;
          }
          console.log(this.productInfo);
          let objProduc = {
            id_prod: this.productInfo[0].id_backoffice,
            ean: this.productInfo[0].ean[0],
            sku: this.productInfo[0].sku[0],
            name: this.productInfo[0].name,
            qty_received: 1,
            price: 0.0,
            iva: this.productInfo[0].tax_iva,
            price_no_iva: 0.0,
            discount: 0.0,
            discount_per: 0.0,
            validity: validity,
            total_price: 0.0,
            total_price_no_iva: 0.0,
            weight: weight,
          };
          this.newProducts.push(objProduc);
          this.newProducts.sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.newProducts[index].quantity++;
          console.log("Produto: ", this.newProducts[index]);
        }
      } else {
        /* SWEET - ALERT; */
        this.$swal({
          icon: "error",
          title: "Ooops",
          text: "Não encontramos o Produto :(",
          showConfirmButton: false,
        });
      }
      this.ean = "";
    },
    async removeProduct(id) {
      const index = this.newProducts.findIndex((obj) => obj.id == id);
      console.log(index);
      this.newProducts.splice(index, 1);
    },
    async updateInvoice() {
      let advance = true;
      let status;
      this.total = this.newProducts.reduce((sum, product) => {
        return sum + Number(product.total);
      }, Number(-this.discount));
      this.totalWithoutIVA = (
        this.newProducts.reduce((sum, product) => {
          return sum + Number(product.total);
        }, Number(-this.discount)) / 1.23
      ).toFixed(2);
      let totalConfirmed = this.newProducts.reduce((sum, product) => {
        return sum + Number(product.quantity);
      }, 0);
      console.log(status);
      console.log("TOTAL INSERIDO", totalConfirmed);
      if (totalConfirmed == 0) {
        this.$swal({
          icon: "error",
          title: "Sem produtos para recepção",
        });
      } else {
        if (this.supplier == "" || this.invoiceId == "") {
          await this.$swal({
            title: "Faltam preencher campos! Deseja continuar?",
            text: "Se os campos forem vazios a encomenda irá para o estado pendente!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, confirmo!",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              advance = true;
            } else {
              advance = false;
            }
          });
          status = "processing";
        } else {
          await this.$swal({
            title: "Confirma que o valor faturado é igual ao valor recebido?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, confirmo!",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              advance = true;
            } else {
              advance = false;
            }
          });
          status = "completed";
        }
        if (advance) {
          try {
            await this.$store.dispatch("updateInvoiceSuppliers", {
              invoiceId: this.invoiceId,
              products: this.newProducts,
              date: this.date,
              supplier: this.supplier,
              status: status,
              total: this.total,
              id: this.$route.params.id,
              totalWithoutIVA: this.totalWithoutIVA,
              discount: this.discount,
            });
            this.$swal(this.getMessage, "", "success");
          } catch (err) {
            this.error = true;
            alert(err);
          }
        }
      }
    },
    splitDate(date) {
      let split = date.split("T");
      return split[0];
    },
    async getAllSuppliers() {
      try {
        await this.$store.dispatch("getAllSuppliers");
        console.log(this.getSuppliers);
        let obj;
        for (let index = 0; index < this.getSuppliers.length; index++) {
          const element = this.getSuppliers[index];
          obj = {
            id: element.id,
            name: `${element.name}`,
            invoice: element.invoice_type,
            contact: element.email,
            nif: element.nif,
            url: element.url,
          };
          this.suppliers.push(obj);
        }
      } catch (error) {
        alert(error);
      }
    },
    async passProductData(data) {
      console.log(data);
      const filterProduct = this.products.filter((product) =>
        product.sku.includes(data.sku)
      );
      console.log(filterProduct);
      this.eanProduct = filterProduct[0].ean;
      this.skuProduct = filterProduct[0].sku;
      this.hsProduct = filterProduct[0].hscode;
      this.warehouseProduct = filterProduct[0].note_warehouse;
      this.notesProduct = filterProduct[0].note_finish;
      this.weightProduct = filterProduct[0].weight;
      this.imgProduct = filterProduct[0].img;
      this.idProduct = filterProduct[0].id_backoffice;
      this.stockProduct = filterProduct[0].stock;
      console.log(this.eanProduct);
      console.log(this.skuProduct);
      console.log(filterProduct, data);
    },
    async updatePurchase() {
      console.log("Novos Produtos", this.newProducts);
      if (this.creditNoteNumber != "" && this.creditNoteValue == "") {
        this.$swal({
          icon: "error",
          title: "Erro",
          text: "Tem de inserir o valor da nota de crédito",
        });
        return;
      }
      //Get supplier id
      const supplier = this.suppliers.find(
        (item) => item.name == this.supplier
      );
      console.log(supplier);

      try {
        const confirmationResult = await this.$swal({
          title: "Confirma que quer editar esta encomenda?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, confirmo!",
          cancelButtonText: "Cancelar",
        });
        console.lo;
        if (!confirmationResult.isConfirmed) return;
        const compare = await this.compareTwoArrays(
          this.newProducts,
          this.newProductsCopy
        );
        console.log("COMPARE: ", compare);
        if (!compare) {
          const totalOriginal = await this.calculateTotalFromArray(
            this.newProducts
          );
          const copyTotal = await this.calculateTotalFromArray(
            this.newProductsCopy
          );
          console.log("Total Original: ", totalOriginal);
          console.log("Total Copy: ", copyTotal);
          const difference = copyTotal - totalOriginal;
          console.log("Diferença: ", difference);
          if (difference > 0) {
            const today = new Date().toISOString().substr(0, 10); // Get today's date in YYYY-MM-DD format
            const refundConfirmationResult = await this.$swal({
              title:
                "Houve alterações nos produtos. Já recebeu a devolução da diferença da encomenda?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, recebi!",
              cancelButtonText: "Não recebi",
            });
            if (refundConfirmationResult.isConfirmed) {
            console.log("Diferença: ", difference);
            const formResult = await this.$swal({
              title: "Valor da devolução",
              html: `<form id="myForm">
                <label for="valueInput">Valor Devolvido:</label><br>
                <input type="number" step="0.01" id="valueInput" name="valueInput" value="${difference}"><br>
                <label for="dateInput">Data da Devolução:</label><br>
                <input type="date" id="dateInput" name="dateInput" value="${today}">
               </form>`,
              preConfirm: () => {
                const valueInput = document.getElementById("valueInput").value;
                const dateInput = document.getElementById("dateInput").value;
                if (!valueInput || !dateInput) {
                  this.$swal.showValidationMessage(
                    `Please enter both a value and a date`
                  );
                }
                return { value: valueInput, date: dateInput };
              },
            });
            if (formResult.isConfirmed){
            await this.$store.dispatch("addAccounting", {
              supplier: supplier.id,
              document: "Recebimento",
              value: formResult.value.value,
              reception_id: this.$route.params.id,
              invoice: this.paymentMethod,
              date: formResult.value.date,
            });
          }
        }
        }
        }
        // Proceed to update purchase only if all conditions above are met
        await this.$store.dispatch("updatePurchase", {
          articles: this.newProducts,
          supplier: supplier.id,
          invoiceId: this.invoiceId,
          paymentMethod: this.paymentMethod,
          totalPay: this.totalPay,
          discount: this.discount,
          id: this.$route.params.id,
          creditNoteValue: this.creditNoteValue,
          creditNoteNumber: this.creditNoteNumber,
        });
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Fatura Editada",
          showConfirmButton: false,
          timer: 1500,
        });

        // window.close();
      } catch (err) {
        alert(err);
      }
    },
    async addImgReception() {
      try {
        await this.$store.dispatch("addImageProductsReceived", {
          id: this.$route.params.id,
          image: this.img_reception,
        });
        this.$swal("Imagem adicionada com sucesso", "", "success");
      } catch (err) {
        alert(err);
      }
    },
    handleFileUpload(event) {
      this.invoiceFile = event.target.files[0];
    },
    openInvoice() {
      console.log(this.file);
      try {
        new URL(this.file);
        window.open(this.file, "_blank");
      } catch (err) {
        // window.open("data:application/pdf;base64,"+this.file, '_blank');
        let binary = atob(this.file);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let blob = new Blob([new Uint8Array(array)], {
          type: "application/pdf",
        });
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    },
    async addFileReception() {
      if (!this.invoiceUrl && !this.invoiceFile) {
        this.$swal({
          icon: "error",
          title: "Erro",
          text: "Tem de inserir um ficheiro da fatura ou um link da fatura",
        });
      }
      try {
        let file = this.invoiceUrl;
        if (this.invoiceFile) {
          file = this.invoiceFile;
        }
        await this.$store.dispatch("addFileReceptions", {
          id: this.$route.params.id,
          file: file,
        });
        this.file = file;
        this.$swal("Ficheiro adicionado com sucesso", "", "success");
      } catch (err) {
        alert(err);
      }
    },
    handleFileCreditNoteUpload(event) {
      console.log(event.target.files[0]);
      this.creditNoteFile = event.target.files[0];
    },
    async openCreditNote() {
      console.log(this.fileCreditNote);
      try {
        new URL(this.fileCreditNote);
        window.open(this.fileCreditNote, "_blank");
      } catch (err) {
        let binary = atob(this.fileCreditNote);
        let array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let blob = new Blob([new Uint8Array(array)], {
          type: "application/pdf",
        });
        let url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    },
    async addCreditNoteFile() {
      console.log(this.creditNoteURL, this.creditNoteFile);
      if (!this.creditNoteURL && !this.creditNoteFile) {
        this.$swal({
          icon: "error",
          title: "Erro",
          text: "Tem de inserir um ficheiro da nota de crédito",
        });
      }
      try {
        let file = this.creditNoteURL;
        if (this.creditNoteFile) {
          file = this.creditNoteFile;
        }
        await this.$store.dispatch("addCreditNoteFile", {
          id: this.$route.params.id,
          file: file,
        });
        this.$swal("Ficheiro adicionado com sucesso", "", "success");
      } catch (err) {
        alert(err);
      }
    },
    async compareTwoArrays(original, copy) {
      try {
        if (original.length != copy.length) {
          return false;
        }
        for (let index = 0; index < original.length; index++) {
          const element = original[index];
          if (element.qty_confirmed != copy[index].qty_confirmed) {
            return false;
          }
        }
        return true;
      } catch (err) {
        alert(err);
      }
    },
    async calculateTotalFromArray(array) {
      try {
        let total = 0;
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          total += element.qty_confirmed * element.price_request;
        }
        return total;
      } catch (err) {
        alert(err);
      }
    },
  },
  async created() {
    this.loading = true;
    // await this.getEAN();
    await this.getAllProducts();
    await this.getAllSuppliers();
    await this.getDataInformation();
    this.loading = false;
  },
  computed: {
    // ...mapGetters(['getProductsEAN']),
    ...mapGetters(["getProducts"]),
    ...mapGetters(["getInvoicesSuppliersId"]),
    ...mapGetters(["getMessage"]),
    ...mapGetters(["getSuppliers"]),
  },
};
</script>

<style scoped>
.box img {
  width: 100%;
  height: 200px;
}
.box1 img {
  object-fit: contain;
}
.box1 {
  border: 3px solid #ff9393;
  border-radius: 5px;
}
.column {
  float: left;
  width: 50%;
  padding: 5px;
}
#searchEan {
  margin-top: 50px;
}
#lblProductEan {
  text-align: right;
}
/* table {
    table-layout: fixed;
  } */
h5 {
  text-align: right;
  margin-right: 7%;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 5px;
}

#infinity:hover {
  cursor: pointer;
  color: aqua;
}
.card {
  padding: 25px;
  background-color: rgba(255, 147, 147, 0.733);
  margin: 10px;
  border: 1px solid #dddd;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
img {
  max-width: 100%;
  border-radius: 18px;
}
#subtitle {
  color: white;
  font-weight: bold;
}
#openArticle {
  color: rgb(0, 98, 255);
}
#openArticle:hover {
  text-decoration: underline;
}
#col1 {
  margin-top: 10px;
}
</style>
