<template>
  <div>
    <h1>ATUALIZAR RECEÇÃO</h1>
    <UpdateReception></UpdateReception>
  </div>
</template>
<script>
import UpdateReception from '@/components/UpdateReceptionProducts.vue';
export default {
  components: {
    UpdateReception,
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
  padding: 10px;
}
</style>
